.app__works {
  width: 100%;
  height: 100%;
  flex-direction: column;
  margin-top: 2em;
}

.work__portfolio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 1em;
  padding: 2em 1.5em 2em 2em;

  @media (max-width: 425px) {
    padding: 0;
  }

}


.work__item {
  width: 80%;
  max-width: 1280px;
  height: 100%;
  margin: 4em auto 6em;
  background-color: #ffdbf3;
  padding: 2em 0;
  position: relative;
  border-radius: 5px;

  &::before {
    content: url(../../assets/decor-tape-1.png);
    position: absolute;
    top: -32%;
    left: -15%;
    transform: scale(0.45);

    @media (min-width: 2000px) {
      top: -35%;
      left: -11%;
    }

    @media (max-width: 1024px) {
      top: -30%;
      left: -28%;
      transform: scale(0.4);
    }

    @media (max-width: 768px) {
      display: none;
    }

  }

  &::after {
    content: url(../../assets/decor-tape-2.png);
    position: absolute;
    bottom: -30%;
    right: -16%;
    transform: scale(0.45);

    @media (min-width: 2000px) {
      bottom: -35%;
      right: -10%;
    }


    @media (max-width: 1024px) {
      bottom: -28%;
      right: -26%;
      transform: scale(0.4);
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    padding: 1.5em;
  }

  @media (max-width: 768px) {
    width: 90%;
  }
  
  @media (max-width: 425px) {
    width: 95%;
    margin: 1em auto 4em;
    padding: 0.8em 1em 1.3em;
  }

}

.work__content {
  width: 90%;
  display: flex;
  justify-content: space-between !important;
  
  @media (max-width: 1024px) {
    width: 100%;
    align-items: flex-start !important;
  }
  
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: flex-start !important;
  }
}

.work__img {
  width: 45%;

  img {
    width: 100%;
    max-width: 500px;
    height: 320px;
    object-fit: cover;
    // border-radius: 5px;
    // box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }

  @media (min-width: 2000px) {
    img {
      // width: 480px;
      // height: 360px;
    }
  }

  @media (max-width: 1024px) {
    width: 40%;

    img {
      min-width: 250px;
      height: 250px;
    }
  }

  @media (max-width: 768px) {
    width: auto;

    img {
      height: 300px;
    }
  }

  @media (max-width: 425px) {
    img {
      min-width: 227px;
      height: 200px;
    }
  }

}

.work__text {
  padding-inline: 0.5em;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  

  h4 {
    line-height: 1.5;
    font-size: 2rem;
    margin: 0 0 0.5em 0;
  }

  p {
    opacity: 0.8;
    line-height: 1.35;
    text-align: justify;
  }

  .work__tags {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin-top: 1em;
    
    span {
      font-size: 0.8rem;
      margin: 0.2em 0.4em;
      background-color: var(--yellow-color);
      padding: 0.3em 0.5em;
    }

  }

  @media (max-width: 1024px) {

    h4 {
      font-size: 1.6rem;
    }

    p {
      font-size: 0.9rem;
    }
  }

  @media (max-width: 768px) {
    align-items: center !important;
    width: 100%;
    max-width: 500px;
    padding: 0;
    
    h4 {
      text-align: center;
      font-size: 1.45rem;
      margin: 0.5em 0;
    }
    
    p {
      text-align: center;
      font-size: 0.8rem;
      padding: 0 0.5em;
    }

    .work__tags {
      justify-content: center;
    }

  }

  @media (max-width: 425px) {
    h4 {
      font-size: 1.3rem;
    }

    p {
      padding: 0;
    }
  }
}


.work__buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 2em auto 0;

  a {
    color: #fff;
    margin: 0 1em 0 0;
    padding: 0.55em 1em;
    background-color: var(--secondary-color);
    border-radius: 20px;
    font-size: 1rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    svg {
      font-size: 1.5rem;
      margin-right: 0.2em;
    }

    &:hover {
      background-color: var(--black-color);
    }
  }

  @media (max-width: 1024px) {

    a {
      width: 45%;
      padding: 0.4em;
    }
  }

  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 0.8em;

    a {
      width: 45%;

    }

    a:nth-child(2) {
      margin-right: 0;
    }
  }

  @media (max-width: 425px) {

    a {
      font-size: 0.9rem;
      padding: 0.3em 1em;

      svg {
        font-size: 1.2rem;
      }
    }
  }

}

