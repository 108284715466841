.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.about__content {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1280px;


  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }

  @media (max-width: 375px) {
    padding-bottom: 1em;
  }
}

.about__text {
  width: 50%;
  max-width: 900px;
  padding: 1em 2em;
  
  h3 {
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-family: var(--font-base);
  }
  
  p {
    text-align: justify;
    margin: 1em 0;
    line-height: 1.5;
    letter-spacing: 0.1px;

    a {
      color: var(--secondary-color);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  
  @media (min-width: 2000px) {
    p {
      font-size: 1.2rem;
    } 
  }
  
  @media (max-width: 768px) {
    width: 100%;
    padding: 0 1em;
  }
  
  @media (max-width: 375px) {
    h3 {
      font-size: 1.24rem;
    }
  
    p {
      font-size: 0.9rem;
    }
  }
}


.about__img {
  width: 30%;
  
  img {
    object-fit: cover;
    width: 300px;
    height: auto;
    border-radius: 10px;
    max-width: 100%;
  }

  @media (max-width: 768px) {
    display: none;
    width: 100%;
    height: 150px;
    
    img {
      width: 100%;
      object-fit: cover;
    }
  }
}

.app__profiles {
  width: 50%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px 0;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    gap: 10px 0px;
  }

}

.app__profile-item {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // margin: 1em;
  color: var(--secondary-color);
  
  img {
    height: 50px;
    object-fit: cover;
    // background: var(--yellow-color);
  }
  
  h2 {
    font-size: 1rem;
    margin-top: 1em;
  }

  p {
    color: (var(--gray-color));
  }

  @media (min-width: 2000px) {
    img {
      height: 65px;
    }
  }

  @media (max-width: 768px) {
    justify-content: flex-start;
    margin: 1em 0.8em;

    img {
      height: 40px;
      
    }
  }

  @media (max-width: 375px) {

    img {
      height: 50px;
    }

    h2 {
      font-size: 0.85rem;
    }
  }

}

