.app {
  background-color: var(--primary-color);
  font-family: var(--font-base);
}

.app__whitebg {
  background-color: var(--white-color);
}

.app__primarybg {
  background-color: #ffffff;
  background: -webkit-gradient(linear, left bottom, left top, from(#ffe3f8), to(#ffffff));
  background: linear-gradient(0deg, #ffe3f8 0%, #ffffff 100%);
}

.app__container {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.app__flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.app__wrapper {
  width: 100%;
  padding: 3em 0em;
  margin: 0 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
}

@media (max-width: 1024px) {
  .app__wrapper {
    padding: 3em 1em;
  }
}

@media (max-width: 500px) {
  .app__wrapper {
    padding: 4em 1.5em 1em;
  }
}

@media (max-width: 425px) {
  .app__wrapper {
    padding: 4em 1.2em 1em;
  }
}

.copyright {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 100%;
  padding: 1.5em 1em 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.copyright small {
  color: var(--gray-color);
  opacity: 0.3;
  font-size: 0.7rem;
}

@media (max-width: 768px) {
  .copyright small {
    font-size: 0.65rem;
  }
}

.head-text {
  font-size: 2.4rem;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  color: var(--black-color);
  text-transform: uppercase;
  margin-bottom: 1em;
}

.head-text span {
  color: var(--secondary-color);
}

@media (min-width: 2000px) {
  .head-text {
    font-size: 3rem;
  }
}

@media (max-width: 768px) {
  .head-text {
    font-size: 2rem;
  }
}

@media (max-width: 425px) {
  .head-text {
    font-size: 1.8em;
  }
}

.p-text {
  font-size: 1.1rem;
  text-align: left;
  color: var(--gray-color);
  line-height: 1.5;
}

@media screen and (min-width: 2000px) {
  .p-text {
    font-size: 1.3rem;
  }
}

.bold-text {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--black-color);
  text-align: left;
}

@media screen and (min-width: 2000px) {
  .bold-text {
    font-size: 2rem;
  }
}

@media screen and (max-width: 450px) {
  .bold-text {
    font-size: 0.9rem;
  }
}
