#home {
  position: relative;
}

.app__header {
  width: 100%;
  height: 100%;
  flex-direction: row;
  position: relative;
  padding-top: 2em;
  background-image: radial-gradient(rgba(255, 90, 195, 0.311) 1.2px, transparent 0);
    background-size: 18px 18px;
    background-position: -20px -20px;


  @media (min-width: 2000px) {
    padding-top: 8em;
  }

  @media (max-width: 450px) {
    // padding: 6em 1em 2em;
  }
}

.header__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  // width: 50%;
  width: 70%;
  height: 100%;
  text-align: right;



  .header__name {
    font-size: 7rem;
    font-family: var(--font-decor);
    font-weight: 800;
    line-height: 1;
    white-space: nowrap;
    letter-spacing: -2px;
    color: var(--black-color);
  }
  
  .header__title {
    font-size: 2rem;
    font-family: var(--font-decor);
    color: var(--black-color);
    margin: 0.3em 0 0;
  }
  
  .header__subtext {
    font-size: 1.5rem;
    font-style: italic;
    font-weight: 200;
    margin: 0.5em 0;
  }
  
  @media (max-width: 768px) {
    width: 90%;

    .header__name {
      font-size: 5rem;
    }
  
    .header__title {
      font-size: 1.4rem;
    }
  
    .header__subtext {
      font-size: 1.2rem;
    }
  
  }

  @media (max-width: 425px) {
    width: 100%;

    .header__name {
      font-size: 4.3rem;
    }

    .header__subtext {
      font-size: 0.9rem;
    }
  }

}



.header__cta {
  margin: 2em 0;
  
  button {
    border: none;
    border-radius: 50px;
    background-color: rgb(255, 231, 11);
    padding: 0.8em 3em;
    user-select: none;
    text-decoration: none;
    font-family: var(--font-base);
    font-weight: 400;
    font-size: 1.1rem;
    color: var(--black-color);
    transition: all 0.2s ease-in-out;
    
    &:hover {
      background-color: var(--secondary-color);
      color: #fff;
    }
    
        @media (max-width: 425px) {
          padding: 0.7em 3.5em;
          font-size: 1rem;
        }
  }

}
