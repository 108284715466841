
.app__testimonial {
  flex: 1;
  flex-direction: column;
  width: 100%;
}

.testimonial__item {
  width: 100%;
  flex-direction: column;
}

.testimonial__card {
  width: 55%;
  max-width: 1000px;
  min-height: 350px;
  background-color: var(--white-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 2em 1.8em 1.3em;
  border-radius: 15px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  position: relative;
  quotes: "\201C""\201D""\2018""\2019";

  &::before {
    position: absolute;
    top: -10%;
    left: -20%;
    content: open-quote;
    font-weight: bold;
    font-size: 200px;
    color:#ff92b8;
    opacity: 0.8;

    @media (max-width: 1024px) {
      top: -10%;
      left: -10%;
      font-size: 180px;
    }

    @media (max-width: 768px) {
      top: -10%;
      font-size: 170px;
    }

    @media (max-width: 425px) {
      top: -10%;
      left: -5%;
      font-size: 120px;
    }
  }

  &::after { 
    position: absolute;
    bottom: -30%;
    right: -20%;
    content: close-quote;
    font-weight: bold;
    font-size: 200px;
    color:#ff92b8;
    opacity: 0.8;

    @media (max-width: 1024px) {
      bottom: -30%;
      right: -10%;
      font-size: 180px;
    }

    @media (max-width: 768px) {
      bottom: -26%;
      right: -10%;
      font-size: 170px;
    }

    @media (max-width: 425px) {
      bottom: -21%;
      right: -5%;
      font-size: 120px;
    }
  }

  @media (max-width: 1024px) {
    width: 75%;
  }


  @media (max-width: 768px) {
    width: 80%;
    min-height: 375px;
  }

  @media (max-width: 425px) {
    width: 95%;
    min-height: 380px;
    padding: 1.5em 1.5em 1em;
  }
}

.testimonial__text {
  height: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  p {
    text-align: center;
    line-height: 1.3;
    color: var(--black-color);
    white-space: pre-line;
    letter-spacing: 0.2px;
    quotes: none;
  }

  @media (max-width: 1024px) {
    p {
      text-align: left;
    }
  }

  @media (max-width: 768px) {
    height: 70%;
    padding: 0;

    p {
      font-size: 0.92rem;
    }
  }
  
  @media (max-width: 425px) {
    padding: 0;

    p {
      font-size: 0.8rem;
    }
  }

}

.testimonial__info {
  height: 55%;
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  img {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    object-fit: cover;
    filter: grayscale(1);
  }
  
  
  @media (min-width: 2000px) {
    img {
      width: 120px;
      height: 120px;
    }
  }

  @media (max-width: 1024px) {
    img {
      width: 100px;
      height: 100px;
    }
  }

  @media (max-width: 768px) {
    height: 35%;

    img {
      width: 90px;
      height: 90px;
    }
  }

  @media (max-width: 425px) {
    height: 25%;
    margin-top: 1em;

    img {
      width: 80px;
      height: 80px;
    }
  }

  
}

.testimonial__titles {
  text-align: center;

  h3 {
    font-size: 1.1rem;
    margin: 0.1em 0;
  }
  
  h4 {
    font-weight: 400;
    font-family: var(--font-base);
    color: var(--secondary-color);
  }

  @media (max-width: 768px) {
    h3 {
      font-size: 1rem;
    }

    h4 {
      font-size: 0.9rem;
    }
  }

  @media (max-width: 425px) {
    h3 {
      font-size: 0.85rem;
    }

    h4 {
      font-size: 0.75rem;
    }

  }

}

.testimonial__buttons {
  margin-top: 1.5em;

  button {
    width: 45px;
    height: 45px;
    border: none;
    border-radius: 50%;
    background-color: var(--white-color);
    margin: 0 1em;
    transition: all 0.3s ease-in-out;

    svg {
      width: 25px;
      height: 25px;
      color: var(--secondary-color);
    }

    &:hover {
      background-color: var(--secondary-color);
      cursor: pointer;
      svg {
        color: var(--white-color);
      }
    }
  }
}

.app__testimonial-brands {
  width: 80%;
  flex-wrap: wrap;
  margin-top: 2em;

  div {
    width: 150px;
    margin: 1.5em;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      filter: grayscale(1);
    }

    &:hover {
      img {
        filter: grayscale(0);
      }
    }

    // Extra large devices
    @media (min-width: 2000px) {
      width: 210px;
      margin: 2em;
    }

    //  Mobile devices
    @media (max-width: 450px) {
      width: 120px;
      margin: 1em;
    }

    // Tablet & large devices
    @media (max-width: 800px) {
      width: 120px;
      margin: 1em;
    }
  }
}