.app__footer {
  flex: 1;
  width: 100%;
  flex-direction: column;
  position: relative;
  z-index: 1;
 
  &::after {
    content: url(../../assets/decor-flower.png);
    display: block;
    position: absolute;
    right: 0%;
    bottom: -10%;
    transform: scale(0.7);
    opacity: 0.75;
    z-index: -1;

    @media (min-width: 2000px) {
      right: 10%;
      bottom: 0;
    transform: scale(0.9);
    }

    @media (max-width: 768px) {
      transform: scale(0.5);
      opacity: 0.7;
      right: -15%;
      bottom: -19%;
    }

    @media (max-width: 425px) {
      right: -40%;
    }


  }
}

.footer__links {
  a {
    font-size: 2.3rem;
    margin: 0 0.3em;
    transition: all 0.2s ease-in-out;
    color: rgb(255, 106, 156);
    
    &:hover {
      color: var(--black-color);
    }
  }

}

.footer__text {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1em;

  p {
    font-size: 1.1rem;
    margin-bottom: 0.5em;

    @media (max-width: 768px) {
      font-size: 0.9rem;
    }
  }
}

.footer__form {
  width: 50%;
  flex-direction: column;

  div {
    width: 100%;
    margin: 0.5em 0;
    border-radius: 10px;
    cursor: pointer;
    background-color: var(--primary-color);
    transition: all 0.3s ease-in-out;

    input, textarea {
      width: 100%;
      padding: 0.6em;
      border: none;
      border-radius: 7px;
      background-color: var(--primary-color);
      font-family: var(--font-base);
      font-size: 0.9rem;
      color: var(--secondary-color);
      outline: 1px solid #ffc0cb;
      // outline: none;

      @media (min-width: 2000px) {
        padding: 0.7em;
        font-size: 1rem;
      }

      @media (max-width: 768px) {
        font-size: 0.9rem;
      }
    }

    textarea {
      height: 170px;
      resize: none;
    }

    &:hover {
      box-shadow: 0 0 25px var(--primary-color); 
    }
  }

  button {
    padding: 0.8em 1.5em;
    border: none;
    border-radius: 25px;
    font-size: 1rem;
    font-family: var(--font-base);
    color: var(--white-color);
    background-color: var(--secondary-color);
    margin-top: 1em;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: var(--black-color);
    }
  }

  @media (min-width: 2000px) {
    width: 40%;
  }

  @media (max-width: 768px) {
    width: 90%;
  }
}

.form__response {
  flex-direction: column;
  text-align: center;
  // padding: 4em 0;

  h3 {
    font-size: 2rem;
  }

  p {
    margin: 1em 0;
    line-height: 1.35;
  }

  button {
    border: none;
    border-radius: 25px;
    margin-top: 1em;
    padding: 0.8em 1.5em;
    font-family: var(--font-base);
    font-size: 0.99rem;
    color: var(--primary-color);
    background-color: var(--secondary-color);
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: var(--black-color);
    }
  }

  @media (max-width: 768px) {
    h3 {
      font-size: 1.5rem;
    }

    p {
      font-size: 0.9rem;
    }

    button {
      font-size: 0.9rem;
    }
  }
}