@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,900;1,400;1,600;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');

:root {
  --font-base: 'Lato', sans-serif;
  --font-decor: 'Playfair Display', serif;


  --primary-color: #fff9fe;
  --secondary-color: #ff75b5;
  --black-color: #141A22;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --brown-color: #46364a;
  --white-color: #ffffff;

  --yellow-color: #ffff0096;
  --pink-color: rgba(255, 117, 181, 0.5);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  cursor: url(https://raw.githubusercontent.com/Lisiman/lisiman.github.io/6fa20ed1e50c83a346663ac6a59f3dbfd9301b31/resources/ZEN-Cursor4.svg), default !important;
}

body {
  font-family: var(--font-base);
  user-select: text;
}

h1, h2, h3, h4 {
  margin: 0;
  font-family: var(--font-decor);
  letter-spacing: 0.3px;
}

::selection {
  background: rgba(255, 255, 0, 0.7);
}

img {
  user-select: none;
}