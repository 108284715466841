.app__skills {
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.skills__text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 1em;
  
  p {
    font-size: 1.2rem;
  }
  
  small {
    margin: 0.5em 0;
    font-size: 0.85rem;
    color: var(--gray-color);

    a {
      color: var(--secondary-color);
      
      &:hover {
        text-decoration: underline 2px solid pink;

      }
    }
  }

  @media (max-width: 768px) {

  }

  @media (max-width: 425px) {
    p {
      font-size: 1rem;
    }

    small {
      font-size: 0.68rem;
    }
  }
}

.skills__container {
  width: 60%;
  height: 45%;
  margin: 1em auto;
  display: flex;
  flex-direction: row;

  @media (min-width: 2000px) {
    width: 40%;
  }

  @media (max-width: 1024px) {
    // width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 768px) {
    width: 90%;
    height: 50%;
  }

}

.skills__list {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: 10% 0;

  @media (max-width: 1024px) {
    margin-right: 0;
    justify-content: center;
    align-items: center;
    // grid-template-columns: repeat(5, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(5, 1fr);
    gap: 5% 0;
  }


  @media (max-width: 425px) {
    grid-template-columns: repeat(3, 1fr);

  }
  


}

.skills__item {
  flex-direction: column;
  text-align: center;
  // margin: 1em;
  transition: all 0.3 ease-in-out;

  div {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: #fff;

    img {
      max-width: 100%;
    }

    &:hover {
      box-shadow: 0 0 25px #fef4f5;
    }

    @media (min-width: 2000px) {
      width: 50px;
      height: 50px;
    }

    @media (max-width: 768px) {
      width: 40px;
      height: 40px;
    }

    @media (max-width: 425px) {
      width: 35px;
      height: 35px;
    }

  }

  p {
    font-size: 0.8rem;
    font-weight: 300;
    margin-top: 0.5em;
    color: var(--gray-color);
    opacity: 0.9;

    @media (max-width: 768px) {
      font-size: 0.7rem;
    }
  }

}
