.app {
  background-color: var(--primary-color);
  font-family: var(--font-base);
}

.app__whitebg {
  background-color: var(--white-color);
}

.app__primarybg {
  background-color: #ffffff;
  background: linear-gradient(0deg, rgb(255, 227, 248) 0%, #ffffff 100%);
}

.app__container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
}

.app__flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__wrapper {
  width: 100%;
  padding: 3em 0em;
  margin: 0 auto;
  flex-direction: column;
  overflow: hidden;


  @media (max-width: 1024px) {
    padding: 3em 1em;
  }

  @media (max-width: 500px) {
    padding: 4em 1.5em 1em;
  }

  @media (max-width: 425px) {
    padding: 4em 1.2em 1em;
  }
}

.copyright {
  user-select: none;
  width: 100%;
  padding: 1.5em 1em 0 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  small {
    color: var(--gray-color);
    opacity: 0.3;
    font-size: 0.7rem;

    @media (max-width: 768px) {
      font-size: 0.65rem;
    }
  }
}

.head-text {
  font-size: 2.4rem;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  color: var(--black-color);
  text-transform: uppercase;
  margin-bottom: 1em;

  span {
    color: var(--secondary-color);
  }

  @media (min-width: 2000px) {
    font-size: 3rem;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 425px) {
    font-size: 1.8em;
  }

}

.p-text {
  font-size: 1.1rem;
  text-align: left;
  color: var(--gray-color);
  line-height: 1.5;

  @media screen and (min-width: 2000px) {
    font-size: 1.3rem;
  }

}

.bold-text {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--black-color);
  text-align: left;

  @media screen and (min-width: 2000px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 0.9rem;
  }
}
